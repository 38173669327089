/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app) => {
        /*
        |
        | Constants
        |-----------
        */
        const
            $body = $('body'),
            $sitecontainer = $('.site-container')
            ;

        /*
        |
        | Menu sticky
        |-----------
        */
        let lastScrollTop = 0;
        function menufix() {
            let scroll = jQuery(window).scrollTop();
            if (scroll >= 50) {
                $sitecontainer.addClass("menufixed");
            } else {
                $sitecontainer.removeClass("menufixed");
            }
        }
        jQuery(window).ready(function () {
            menufix();
        });
        jQuery(window).scroll(function () {
            menufix();
        });

        /*
        |
        | Sous menu
        |-----------
        */
        jQuery(document).ready(function () {
            $('.item-menu li').hover(function () {
                $(this).find('.nav-drop').stop().slideToggle();
            });
        });


        /*
        |
        | Filtre ecosysteme
        |-----------
        */
        $('.js-filter').on('click', function () {
            let $filter = $(this).attr('data-filter');
            $('.js-filter').removeClass('btn-alt');
            $(this).addClass('btn-alt');

            if ($filter == 'all') {
                $('.js-filterable').fadeIn(500);
            } else {
                $('.js-filterable').fadeOut(0);
                $('.js-filterable[data-filter=' + $filter + ']').fadeIn(500);
            }
        });

        /*
        |
        | Scroll anchor
        |-----------
        */
        $('.list-anchor a[href*="#"]:not([href="#"])').click(function () {
            let offset = -150; // <-- change the value here
            if (
                location.pathname.replace(/^\//, "") ==
                this.pathname.replace(/^\//, "") &&
                location.hostname == this.hostname
            ) {
                let target = $(this.hash);
                target = target.length
                    ? target
                    : $("[name=" + this.hash.slice(1) + "]");
                if (target.length) {
                    $("html, body").animate(
                        {
                            scrollTop: target.offset().top + offset,
                        },
                        1000
                    );
                    return false;
                }
            }
        });

        /*
        |
        | Chiffres
        |-----------------
        */
        if ($(".section_numbers").length) {
            let a = 0;
            $(window).scroll(function () {
                let oTop = $('.section_numbers').offset().top - window.innerHeight;

                if (a == 0 && $(window).scrollTop() > oTop) {
                    $('.counterUp').each(function () {
                        let $this = $(this),
                            countTo = $this.attr('data-count');
                        $({
                            countNum: $this.text()
                        }).animate({
                            countNum: countTo
                        },
                            {
                                duration: 2000,
                                easing: 'swing',
                                step: function () {
                                    $this.text(Math.floor(this.countNum));
                                },
                                complete: function () {
                                    $this.text(this.countNum);
                                    //alert('finished');
                                }

                            });
                    });
                    a = 1;
                }
            });
        }

    }
}

/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, Swiper) => {
        /*
        |
        | Constants
        |-----------
        */
        const
            $body = $('body'),
            $sitecontainer = $('.site-container')
            ;

        /*
        |
        | Swiper témoignages
        |-----------
        */



        let galleryThumbs = new Swiper('#swiper-tem-thumbs', {
            slidesPerView: 5,
            freeMode: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
        });
        let galleryTop = new Swiper('#swiper-tem', {
            spaceBetween: 10,
            navigation: {
                nextEl: '.button-next',
                prevEl: '.button-prev',
            },
            thumbs: {
                swiper: galleryThumbs
            }
        });





    }
}

/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, gsap, ScrollTrigger, Menu, Kira, GSDevTools) => {
        /*
        |
        | Constants
        |-----------
        */
        const
            html = document.querySelector('html'),
            body = document.querySelector('body'),
            $body = $('body'),
            $pageLoader = $('.page-loader'),
            scrollAreas = document.querySelectorAll('.scroll-area'),
            $splitItems = $body.find('[data-splittext]'),
            $menuWrapper = $('#mobile-menu'),
            $menuButton = $('#header .btn-menu'),
            $menuBars = $menuButton.find('.item-burger > span'),
            $menuClose = $menuWrapper.find('.item-close'),
            $menuStaggerItems = $menuWrapper.find('[data-stagger-item]')
            ;


        /*
        |
        | SplitText
        |------------
        */
        $.each($splitItems, function () {
            const $item = $(this);
            const type = $item.data('splittext') ? $item.data('splittext') : 'words, chars';
            const split = new SplitText($item, { type: type, linesClass: 'item-line', wordsClass: 'item-word', charsClass: 'item-char' });

            $item[0]['split'] = split;
        });

        $body.on('dom:ready', () => {
            $splitItems.addClass('split-ready')
        })


        /*
        |
        | Scroll Areas
        |---------------
        */
        Array.prototype.forEach.call(scrollAreas, element => {
            Scrollbar.init(element);
        });


        /*
        |
        | Loader
        |---------
        */
        if (sessionStorage.getItem('loaded_once') === null) {
            sessionStorage.setItem('loaded_once', 'loaded_once');
        }

        if ($pageLoader.hasClass('active')) {
            const loaderTl = gsap.timeline({ paused: true, /*onComplete: () => $pageLoader.remove()*/ });

            loaderTl.to($pageLoader.find('.item-loadbar-inner'), 0.4, { scaleX: 1, ease: 'Power0.easeNone' }, 'start')
            loaderTl.to($pageLoader.find('.item-content'), 0.8, { autoAlpha: 0, ease: 'Power1.easeOut' }, '-=0')
            loaderTl.call(() => { app.dispachEvent(body, 'dom:ready'); }, null)
            loaderTl.to($pageLoader, 0.8, { autoAlpha: 0, ease: 'Power1.easeOut' }, '-=0')

            $(window).on('load', function () {
                $('body').removeClass('--loading');
                loaderTl.play();
            });
        } else {
            $(window).on('load', function () {
                $('body').removeClass('--loading');
                app.dispachEvent(body, 'dom:ready');
            })

        }


        /*
        |
        | Menu
        |-------
        */
        const
            menuWrapper = document.querySelector('#mobile-menu'),
            menuButton = document.querySelector('#header .btn-menu')
            ;

        const menu = new Menu(menuWrapper, menuButton, {
            reverseTimeScale: 2
        });

        menu.menuTimeline
            .to($menuBars.eq(1), 0.3, { autoAlpha: 0 }, 'start')
            .to($menuBars.eq(0), 0.5, { x: 0, y: 8, rotation: 45, ease: 'Power1.easeOut' }, 'start')
            .to($menuBars.eq(2), 0.5, { x: 0, y: -8, rotation: -45, ease: 'Power1.easeOut' }, 'start')
            .to($menuWrapper, 0.5, { autoAlpha: 1, ease: 'Power1.easeOut' }, 'start+=0.3')
            .staggerFrom($menuStaggerItems, 0.6, { autoAlpha: 0, x: 20, ease: 'Power1.easeOut' }, '0.1', '+=0')

        menu.init();

        /*
        |
        | Kira
        |-----------
        */
        const kira = new Kira({
            loadEvent: [body, 'dom:ready'],
            scrollTrigger: {
                markers: false,
                //scroller: scrollContainerSelector,
            },
            tweenParams: {
                start: '-=0.6'
            }
        });

        $(window).on('resize', function () {
            ScrollTrigger.refresh();
        });

        const virguleTl = new gsap.timeline({ paused: true })

        /*const virgules = gsap.utils.toArray('a__svg-item')
        virguleTl.from('.a__svg', { autoAlpha: 0.5, scale: 0.3, ease: "Expo.easeInOut", duration: 6}, 'start')
                    .to(virgules, { rotation: '+=90', transformOrigin: '100% top' },'start+=3')*/
        virguleTl.from('.a__svg', { autoAlpha: 0.5, scale: 0, ease: "Expo.easeInOut", duration: 4}, 'start')
                 .to('.a__svg', { rotation: '+=90', duration: 3.25 },'start+=0.5')
                 .to('.a__svg-top', { rotation: '+=90', transformOrigin: '100% top', duration: 0.5 },'start+=1')
                 .to('.a__svg-left', { rotation: '+=180', transformOrigin: '100% top', duration: 1 },'start+=1')
                 .to('.a__svg-right', { rotation: '+=270', transformOrigin: '100% top', duration: 1.5 },'start+=1')
                 .to('.a__svg-top', { autoAlpha: 0, duration: 1.25 },'start+=3.25')
                 .to('.a__svg-left', { autoAlpha: 0, duration: 1.25 },'start+=3.25')
                 .to('.a__svg-right', { autoAlpha: 0, duration: 1.25 },'start+=3.25')
                 .to('.a__svg', { y: "100%", x: "50%", ease: "Power2.easeInOut", duration: 2.75 },'start+=1')
                 .from($('.a__title').find('> div, > em, > span'), { autoAlpha: 0, y: '101%', ease: 'easeSmooth', duration: 1.3 },'start+=3.35')
                 .from('.a__text', { autoAlpha: 0, y: 50, ease: 'easeSmooth', duration: 1.3, stagger: 0.1 }, "-=0.5")

                 // GSDevTools.create();      
                 /*
                 const boxes = gsap.utils.toArray('.box');

                containerRights = $("#rights")

                gsap.set(boxes, { y: "30%", x: "50%",opacity: 0 });

                gsap.to(".box", {
                    x: "0",
                    y: "0",
                    opacity: 1,
                    scrollTrigger: {
                        id: "trigger",
                        trigger: containerRights,
                        start: "top 80%",
                        end: "bottom 60%",
                        scrub: true
                    },
                    stagger: 0.4
                });         
                */

        $(body).on('dom:ready', function(){
            virguleTl.play()
        })

        /*
        const virguleTl = new gsap.timeline({ paused: true })

        virguleTl.from('.a__svg', {rotation: 600, scale: 0, ease: "Expo.easeInOut", duration: 5}, 'start')
                 .to('.a__svg-top', { autoAlpha: 0, y: -300 }, "start+=4")
                 .to('.a__svg-left', { autoAlpha: 0, x: -300 }, "start+=4")
                 .to('.a__svg-right', { autoAlpha: 0, x: 300 }, "start+=4")
                 .from($('.a__title').find('> div, > em, > span'), { autoAlpha: 0, y: '101%', ease: 'easeSmooth', duration: 1.3 })
                 .from('.a__text', { autoAlpha: 0, y: 50, ease: 'easeSmooth', duration: 1.3, stagger: 0.1 }, "-=0.5")


        $(body).on('dom:ready', function(){
            virguleTl.play()
        })*/
        

        ScrollTrigger.matchMedia({
            "(min-width: 1200px)": function () {
                gsap.set('.a__svg-bottom', {});
                const rotate1200 = gsap.timeline({
                    scrollTrigger: {
                        trigger: ".menufixed",
                        pin: true,
                        scrub: 0.2,
                        scale: 1,
                        start: 'top top',
                        end: '+=500',
                        opacity: 0.4
                    }
                })
                    .to('.a__svg-bottom', {
                        rotation: 127,
                        x: 350,
                        y: -250,
                        scale: 0.3,
                        opacity: 1,
                        duration: 1, ease: 'none'
                    })
            },
            "(max-width: 1199px) and (min-width: 992px)": function () {
                gsap.set('.a__svg-bottom', {});
                const rotate1199 = gsap.timeline({
                    scrollTrigger: {
                        trigger: ".menufixed",
                        pin: true,
                        scrub: 0.2,
                        scale: 1,
                        start: 'top top',
                        end: '+=500',
                        opacity: 0.4
                    }
                })
                    .to('.a__svg-bottom', {
                        rotation: 127,
                        x: 180,
                        y: -250,
                        scale: 0.35,
                        opacity: 1,
                        duration: 1, ease: 'none'
                    })
            },
            "(max-width: 991px)": function () {
                gsap.set('.a__svg-bottom', {});
                const rotate991 = gsap.timeline({
                    scrollTrigger: {
                        trigger: ".menufixed",
                        pin: true,
                        scrub: 0.2,
                        scale: 1,
                        start: 'top top',
                        end: '+=500',
                    }
                })
                    .to('.a__svg-bottom', {
                        rotation: 127,
                        x: 450,
                        y: 0,
                        scale: 0.5,
                        duration: 1, ease: 'none'
                    })
            }
        });

        /*
        | fadeInUp
        |-----------
        */
        kira.add('fadeInUp', (item, timeline, start) => {
            timeline.fromTo(item, 1.3, { y: 50 }, { y: 0, autoAlpha: 1, ease: 'easeSmooth' }, start)
        });

        /*
        | fadeInUp.parallax.sm
        |-----------------------
        */
        kira.add('fadeInUp.parallax', (item, timeline, start) => {
            timeline.fromTo(item, 0.8, { y: 100 }, { y: 0, autoAlpha: 1, ease: 'Sine.easeOut' }, start)
        });

        /*
        | splitline
        |------------
        */
        kira.add('splitline', (item, timeline, start) => {
            const delay = item.dataset.delay ? item.dataset.delay : 0.012;

            item.querySelectorAll('.item-line').forEach(line => {
                timeline.from($(line).find('> div, > em, > span'), 1.35, { y: '101%', ease: 'easeSmooth' }, start)
            })
        });

        /*
        | splittext.long
        |-----------------
        */
        kira.add('splittext.long', (item, timeline, start) => {
            const delay = item.dataset.delay ? item.dataset.delay : 0.01;

            timeline.staggerFrom(item.split.chars, 0.8, { y: 5, opacity: 0, ease: 'Sine.ease0ut' }, delay, start)
        });

        /*
        | fadeInLeft.parallax.sm
        |-------------------------
        */
        kira.add('fadeInLeft.stagger.sm', ($item, timeline, start) => {
            timeline.staggerFromTo($item.querySelectorAll('[data-stagger-item]'), 0.6, { x: 20 }, { autoAlpha: 1, x: 0, ease: 'Power1.easeOut' }, '0.1', start)
        });

        /*
        | fadeInUp.parallax.sm
        |-------------------------
        */
        kira.add('fadeInUp.stagger.sm', ($item, timeline, start) => {
            timeline.staggerFromTo($item.querySelectorAll('[data-stagger-item]'), 1, { x: 20 }, { opacity: 1, x: 0, ease: 'Power1.easeOut' }, '0.05', start)
        });

        kira.init();
    }
}
